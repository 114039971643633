// Bootstrap colors
$primary: #5D0476;
$secondary: #04c8ff;
$danger: #e60101;

// Material Design colors
// Generated with: http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5
$md-primary: (
    50 : #ece1ef,
    100 : #ceb3d6,
    200 : #ae81bb,
    300 : #8e4f9f,
    400 : #75298b,
    500 : #5d0376,
    600 : #55036e,
    700 : #4b0263,
    800 : #410259,
    900 : #300146,
    A100 : #cb7aff,
    A200 : #b847ff,
    A400 : #a414ff,
    A700 : #9900fa,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #e1f8ff,
    100 : #b4efff,
    200 : #82e4ff,
    300 : #4fd9ff,
    400 : #2ad0ff,
    500 : #04c8ff,
    600 : #03c2ff,
    700 : #03bbff,
    800 : #02b4ff,
    900 : #01a7ff,
    A100 : #ffffff,
    A200 : #f2faff,
    A400 : #bfe6ff,
    A700 : #a6dcff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-danger: (
    50 : #fce1e1,
    100 : #f8b3b3,
    200 : #f38080,
    300 : #ee4d4d,
    400 : #ea2727,
    500 : #e60101,
    600 : #e30101,
    700 : #df0101,
    800 : #db0101,
    900 : #d50000,
    A100 : #fffcfc,
    A200 : #ffc9c9,
    A400 : #ff9696,
    A700 : #ff7d7d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

:export {
  primary: $primary;
  secondary: $secondary;
  danger: $danger;
}
