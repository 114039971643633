@import 'rightbrains-variables.scss';
@import 'rightbrains-colors.scss';

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.dashboard-title {
  text-align: center;
  color: $primary;
  display: flex;
  justify-content:left;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-left: 10%;

  @media screen and (min-width: 1280px){
    margin-left: 25%
  }

  @media screen and (min-width: 1000px) and (max-width: 1280px) {
    margin-left: 20%
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    margin-left: 15%
  }
}

.underlined-title {
  display: inline-block;
  margin: 10px 0;
  line-height: 2rem;
  color: $primary;

  p {
    position: relative;
    font-size: 34px;
    z-index: 2;
    margin: 0 !important;
    font-weight: bold;
  }

  div {
    width: calc(100% + 40px);
    left: -20px;
    top: -9px;
    height: 18px;
    background-color: #00c9ff;
    position: relative;
    z-index: 1;
  }

  @media screen and (max-width: 400px) {
    p {
      font-size: 9vw;
    }

    div {
      height: 10px;
    }
  }
}

.subtitle {
  font-size: 18px;
}

.align-center {
  text-align: center;
  width: 100%;
}

.mat-mdc-snack-bar-container.success-snackbar {
  background: darkgreen;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.dropdown-down {
  border-top: 10px solid $primary !important;
}

.dropdown-up {
  border-bottom: 10px solid $primary !important;
}

/* Custom material css overwrites to accomodate pre dependency upgrade look */
.mat-toolbar, .mat-typography, .mat-typography h2, .mat-typography h3, .mat-typography h1, .mat-mdc-dialog-container {
  letter-spacing: normal !important;
}

.mat-dialog-content {
  padding: 24px;
  margin: -24px;
}

.mat-mdc-dialog-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mat-dialog-container, .mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-mdc-text-field-wrapper {
  background-color: white !important;
}



/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: $primary !important;  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $primary !important; /*inner circle color change*/  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary !important; /*outer ring color change*/  }

.mat-mdc-snack-bar-container.failed-snackbar {
  background: darkred;
  color: white;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label-wrapper mat-label {
  font: 400 14px/20px "Open Sans", sans-serif;
  color: black !important;
  font-size: 14px !important;
  font-weight: 700;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix {
  .input-container {
    display: flex;
  }
  mat-icon {
    font-size: 20px;
    margin-left: 4px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.5em) !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-label-wrapper label {
  padding-bottom: 1px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-trigger span {
  font-size: 14px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-infix input {
  font-size: 14px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.desktop-menu-item .menu-item-active .mat-list-item-content {
  color: $secondary !important;
}

.multiselect-dropdown {
  .dropdown-list {
    @media screen and (max-width: 800px) {
      position: relative !important;
    }
    overflow-y: hidden !important;
  }
  .dropdown-btn {
    .selected-item {
      border-radius: 15px !important;
      color: black !important;
      background: $secondary !important;
      padding: 7px !important;
      border: 0 !important;
      margin: 4px !important;
      padding-right: 10px !important;
      max-width: inherit !important;
      a {
        background: rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        color: transparent !important;
        text-align: center;
        background-image: url('assets/images/cancel_icon.png');
        background-size: cover;
        margin-left: 5px;
        opacity: 0.4;
        padding-right: 13px;
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }

  .multiselect-item-checkbox {
    input {
      width: 20px;
      height: 20px;
    }
    div::before {
      background: $primary !important;
      color: white !important;
      border-radius: 3px !important;
      border: 0 !important;
      margin-right: 5px !important;
      width: 20px !important;
      height: 18px !important;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-should-float {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-mdc-input-element {
    margin-top: 5px !important;
  }

  .mat-select-trigger {
    margin-top: 5px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
.mat-mdc-select,
textarea {
  font-size: 16px;
  line-height: 16px;
}

@media screen and (max-width: 800px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  body,
  #main-content {
    -ms-overflow-style: none;
  }
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

.mat-step-label {
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}
.mat-drawer {
  position: absolute !important;
  top: 0 !important;
}
.mat-drawer-inner-container {
  width: 100vw !important;
  position: relative;
  top: -65px;
  height: 102vh !important;
  background: $primary !important;
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    color: white !important;
    font-weight: 400;
  }
}

.mat-mdc-unelevated-button.mat-primary,
.mat-flat-button.mat-warn,
.mat-mdc-raised-button.mat-primary,
.mat-raised-button.mat-warn,
.mat-raised-button.mat-basic {
  border-radius: 27px;
  padding: 3px 20px;
  font-weight: bold;
  outline: 0 !important;
}

.mat-mdc-outlined-button.mat-primary {
  border-radius: 27px;
  padding: 4px 20px;
  font-weight: bold;
}

.mat-mdc-checkbox {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-inner-container {
    padding: 10px;
    border: 2px solid $primary;
    border-radius: 5px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-frame {
    border-width: 0;
  }
}

.mat-mdc-chip.mat-mdc-standard-chip {
  height: unset;
}

.mat-mdc-unelevated-button.mat-primary[disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-mdc-raised-button.mat-primary[disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled],
.mat-mdc-fab.mat-primary[disabled],
.mat-fab.mat-accent[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-mdc-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled] {
  background-color: #ccc !important;
}

.mat-mdc-outlined-button:not([disabled]) {
  background-color: #fff;
  border: 1px solid currentColor !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #333 !important;
  line-height: 24px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-label {
  white-space: normal;
}

.avatar {
  height: 50px !important;
  width: 50px !important;
  border-radius: 36px;
  font-size: 50px !important;
  object-fit: cover;
  mat-icon {
    font-size: 50px !important;
  }
}

.btn-xs {
  width: 120px !important;
}

@media screen and (max-width: 800px) {
  .btn-xs-mobile {
    width: 120px !important;
  }
}

button {
  outline: 0 !important;
}

.btn-sm {
  width: 250px !important;
}

.btn-lg {
  width: 400px !important;
}

.bevel {
  width: 100%;
  height: 1px;
  background-color: $primary;
  margin-top: 15px;
}

.section-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  margin-top: 30px;
  margin-bottom: 0;
}

.edit-cell {
  float: right;
  margin-top: 30px;
  margin-bottom: 0;
}

.edit-link {
  color: rgba(49, 51, 62, 0.5);
}

.edit-pencil {
  color: $primary;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.e-toast-container .e-toast:hover {
  background-color: white;
}

.e-toast-container .e-toast {
  background-color: white;
  padding: 10px;

  .e-toast-close-icon {
    color: black;
    margin-bottom:auto;
    margin-top: auto;
  }

  .e-toast-message .e-toast-content {
    color: black;
  }
}

.toast-message td {
  padding: 10px
}


.toast-popup {
  max-width: 500px;
  margin: auto;
}
@media screen and (min-width: 800px) {
  .cdk-overlay-pane {
    max-width: calc(max(40vw, 850px)) !important;
  }
}

